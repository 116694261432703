import * as React from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import { Button } from "./ui/button"; 
import { Input } from "./ui/input"; 
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";

const initialData = [
  {
      "team_id": "U3OX2N93",
      "team_name": "Team-MakeChange",
      "score": "384"
  },
  {
      "team_id": "U85A6J8W",
      "team_name": "AI Alchemists",
      "score": "293"
  },
  {
      "team_id": "UU999E7S",
      "team_name": "H2O",
      "score": "262"
  },
  {
      "team_id": "UXL575T0",
      "team_name": "Code Potatoes",
      "score": "244"
  },
  {
      "team_id": "U58UH59C",
      "team_name": "ITGEEKS",
      "score": "226"
  },
  {
      "team_id": "UAN5890B",
      "team_name": "Krishnendu&Akshara",
      "score": "222"
  },
  {
      "team_id": "UY861GZ0",
      "team_name": "Compile crew",
      "score": "208"
  },
  {
      "team_id": "U254OTP5",
      "team_name": "Code clash",
      "score": "204"
  },
  {
      "team_id": "U569XYW5",
      "team_name": "Stumble guys",
      "score": "197"
  },
  {
      "team_id": "UB960ED3",
      "team_name": "Dune Dev Squad",
      "score": "184"
  },
  {
      "team_id": "UK4746YF",
      "team_name": "skcoder",
      "score": "182"
  },
  {
      "team_id": "UJ0I93Z0",
      "team_name": "Catalyst",
      "score": "164"
  },
  {
      "team_id": "U7A51IL8",
      "team_name": "TEAM GALAHAD",
      "score": "160"
  },
  {
      "team_id": "U416VDA6",
      "team_name": "NAVI ",
      "score": "154"
  },
  {
      "team_id": "UE76OC69",
      "team_name": "Megabytes",
      "score": "153"
  },
  {
      "team_id": "U8OWA653",
      "team_name": "Dynamic Duos",
      "score": "147"
  },
  {
      "team_id": "U2O0XG18",
      "team_name": "Code racers",
      "score": "130"
  },
  {
      "team_id": "U3Z8SO47",
      "team_name": "CodeDuo",
      "score": "129"
  },
  {
      "team_id": "U598UPK9",
      "team_name": "The Boys",
      "score": "127"
  },
  {
      "team_id": "U0JFK121",
      "team_name": "BusyBees",
      "score": "116"
  },
  {
      "team_id": "U8O9J4K5",
      "team_name": "Quantum Coders",
      "score": "113"
  },
  {
      "team_id": "UV593MH2",
      "team_name": "Techduo",
      "score": "111"
  },
  {
      "team_id": "U7X8F9V8",
      "team_name": "JoelOswin",
      "score": "103"
  },
  {
      "team_id": "U47FA3N9",
      "team_name": "Tech Troop",
      "score": "100"
  },
  {
      "team_id": "U8IK818T",
      "team_name": "Byte Club",
      "score": "97"
  },
  {
      "team_id": "UK4MY408",
      "team_name": "CodeBlazers ",
      "score": "93"
  },
  {
      "team_id": "U331Z1DW",
      "team_name": "Synapse squad",
      "score": "91"
  },
  {
      "team_id": "UR4183EQ",
      "team_name": "Elite Prowess",
      "score": "84"
  },
  {
      "team_id": "UV35OX83",
      "team_name": "APsquad",
      "score": "74"
  },
  {
      "team_id": "UM3P74Z0",
      "team_name": "Dexterity",
      "score": "67"
  },
  {
      "team_id": "UN97A74W",
      "team_name": "Coders",
      "score": "64"
  },
  {
      "team_id": "UMQ61E73",
      "team_name": "More To Know",
      "score": "63"
  },
  {
      "team_id": "US21O4B9",
      "team_name": "INNOVATORS",
      "score": "62"
  },
  {
      "team_id": "UC8R251B",
      "team_name": "NPCs",
      "score": "62"
  },
  {
      "team_id": "U75I51SP",
      "team_name": "//ToFigureOutLater",
      "score": "59"
  },
  {
      "team_id": "U51Z5Y5W",
      "team_name": "Team Rizz",
      "score": "48"
  },
  {
      "team_id": "UH3M58D3",
      "team_name": "Cool and Good",
      "score": "46"
  },
  {
      "team_id": "U6OL481Q",
      "team_name": "cegcoders",
      "score": "43"
  },
  {
      "team_id": "U492N3YI",
      "team_name": "Strings",
      "score": "36"
  },
  {
      "team_id": "USP3993T",
      "team_name": "Thunder Falcons",
      "score": "33"
  },
  {
      "team_id": "U36Z0J3X",
      "team_name": "Maverick",
      "score": "33"
  },
  {
      "team_id": "UT7086KY",
      "team_name": "Code Masters",
      "score": "32"
  },
  {
      "team_id": "UZ78HY39",
      "team_name": "CC2",
      "score": "31"
  },
  {
      "team_id": "U46MA87G",
      "team_name": "Hackos",
      "score": "31"
  },
  {
      "team_id": "UZJ618I9",
      "team_name": "Neeraj",
      "score": "31"
  },
  {
      "team_id": "U2M67KW6",
      "team_name": "D_CODE",
      "score": "26"
  },
  {
      "team_id": "UM3T48E9",
      "team_name": "Strivers",
      "score": "26"
  },
  {
      "team_id": "UPB84Q07",
      "team_name": "Black Pearl",
      "score": "26"
  },
  {
      "team_id": "U1SJ915A",
      "team_name": "AK Team",
      "score": "25"
  },
  {
      "team_id": "U42EVJ32",
      "team_name": "The Queen",
      "score": "24"
  },
  {
      "team_id": "UP10L8X2",
      "team_name": "KNIGHTS",
      "score": "18"
  },
  {
      "team_id": "U53HQ61G",
      "team_name": "Bit Developer's",
      "score": "18"
  },
  {
      "team_id": "UZ5SV238",
      "team_name": "ARUL Team",
      "score": "18"
  },
  {
      "team_id": "U5N596UK",
      "team_name": "ZLIQ",
      "score": "12"
  },
  {
      "team_id": "UC084P2D",
      "team_name": "Koshan",
      "score": "9"
  },
  {
      "team_id": "U5YF263A",
      "team_name": "Coderockers",
      "score": "7"
  },
  {
      "team_id": "U8R557XV",
      "team_name": "Achievers",
      "score": "7"
  },
  {
      "team_id": "U8RTW563",
      "team_name": "Tech Titans",
      "score": "7"
  },
  {
      "team_id": "U2HM5F14",
      "team_name": "Brute Force",
      "score": "7"
  },
  {
      "team_id": "UV8JY302",
      "team_name": "Tech Trekkers",
      "score": "5"
  },
  {
      "team_id": "US57KT58",
      "team_name": "Flyers",
      "score": "5"
  },
  {
      "team_id": "U6Z3YJ32",
      "team_name": "Zer0ne",
      "score": "5"
  },
  {
      "team_id": "U47P27YQ",
      "team_name": "Sree",
      "score": "3"
  },
  {
      "team_id": "U2H7W93R",
      "team_name": "Moon knight",
      "score": "3"
  }
]

const columns = [
  {
    accessorKey: "team_id",
    header: "Team Id",
  },
  {
    accessorKey: "team_name",
    header: "Team Name",
  },
  {
    accessorKey: "score",
    header: "Score",
  },
];

export default function Leaderboard() {
  const [sorting, setSorting] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [filteredData, setFilteredData] = React.useState(initialData);

  // Function to filter the data based on the global filter
  const filterData = (data, filter) => {
    if (!filter) return data;
    return data.filter((item) =>
      Object.values(item).some((value) =>
        value.toLowerCase().includes(filter.toLowerCase())
      )
    );
  };

  // Update filtered data whenever globalFilter changes
  React.useEffect(() => {
    setFilteredData(filterData(initialData, globalFilter));
  }, [globalFilter]);

  const table = useReactTable({
    data: filteredData,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      globalFilter,
    },
  });

  return (
    <div className="w-full bg-gray-400 rounded-md  bg-opacity-10 p-9 ">
        <h2 className="text-xl font-bold text-white mx-auto mb-4 mt-16">CODE CYCLE LIVE LEADERBOARD👑</h2>
      <div className="flex items-center py-4">
        <Input
          placeholder="Search..."
          value={globalFilter}
          onChange={(event) => setGlobalFilter(event.target.value)}
          className="max-w-sm text-black placeholder-grey"
        />
      </div>
      <div className="rounded-md border border-gray-600 bg-opacity-0"> 
        <Table className="text-white ">
          <TableHeader border-b border-gray-600>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead
                    key={header.id}
                    className="p-2 text-white text-center bg-gray-900"
                  >
                    {header.isPlaceholder ? null : header.column.columnDef.header}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} className="hover:bg-gray-900">
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="text-white p-2 font-bold  bg-gray-600 bg-opacity-20 border-b border-gray-700">
                      {cell.getValue()}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center text-white">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <Button
          variant="outline"
          size="sm"
          className="bg-gray-600 text-white border-gray-500" 
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </Button>
        <Button
          variant="outline"
          size="sm"
          className="bg-gray-600 text-white border-gray-500" 
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </Button>
      </div>
      <p>Last Updated at 12/09/2024 10:00PM</p>
    </div>
  
  );
}




